<template>
  <qqMapSelectPoint :mapcenter="centerLatLng" :oldmarker="oldMarker" @mapclick="pointChange"></qqMapSelectPoint>
</template>

<script>
import qqMapSelectPoint from "./selectPoint";
export default{
  components: {
    qqMapSelectPoint
  },
  props:{
    lat:{
      type:String,
      default:"39.927378266437024"
    },
    long:{
      type:String,
      default: "116.39019012451172"
    }
  },
  data: function () {
    return {
      pointName: '北京市',
      centerLatLng: '39.927378266437024,116.39019012451172',
    }
  },
  computed:{
    oldMarker(){
      let lat = "";
      let long = "";
      this.lat ? lat = this.lat : lat = "39.927378266437024";
      this.long ? long = this.long : long = "116.39019012451172";
      return `${lat},${long}`
    },
  },
  mounted(){
  },
  methods: {
    pointChange(ev){
      this.$emit("point",ev.lat,ev.lng);
    }
  }
}
</script>

<style>

</style>