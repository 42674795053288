<template>
  <y_layout>

    <el-card shadow="hover" :body-style="{padding:'1rem 1rem'}" style="width: 100%;">
      <el-page-header @back="$router.back()" content="商家门店列表"></el-page-header>
      <div style="padding: 1rem;text-align: right">
        <el-button @click="dialogTableVisible=true" size="medium" type="primary" icon="el-icon-edit">新增</el-button>
      </div>
      <el-table v-loading="loading" :data="list" border style="width: 100%">
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column prop="title" label="门店名称"></el-table-column>
        <el-table-column prop="tel" label="电话"></el-table-column>
        <el-table-column prop="address" label="地址"></el-table-column>
        <el-table-column fixed="right" label="操作" align="center">
          <template #default="scope">
            <el-button @click="edit(scope.row)" type="primary" size="small">编辑</el-button>
            <el-button @click="del(scope.row.id)" size="small">删除</el-button>
          </template>
        </el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-card>
    <el-dialog title="编辑门店" :visible.sync="dialogTableVisible" width="60rem" @close="closeForm">
      <div class="flex-def flex-zCenter flex-zBetween">
        <el-form :model="form" label-width="4rem" style="width: 25rem">
          <el-form-item label="名称">
            <el-input v-model="form.title"></el-input>
          </el-form-item>
          <el-form-item label="地址">
            <el-input v-model="form.address"></el-input>
          </el-form-item>
          <el-form-item label="经纬度">
            <el-row :gutter="20">
              <el-col :span="10"><el-input v-model="form.lat"></el-input></el-col>
              <el-col :span="1">，</el-col>
              <el-col :span="10"><el-input v-model="form.long"></el-input></el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="电话">
            <el-input v-model="form.tel"></el-input>
          </el-form-item>
          <el-form-item align="right">
            <el-button @click="submit" type="primary">保存</el-button>
          </el-form-item>
        </el-form>
        <div style="width: 30rem">
          <add @point="point" :lat="form.lat" :long="form.long"></add>
        </div>
      </div>
    </el-dialog>
  </y_layout>
</template>

<script>
import y_layout from "@/components/common/y_layout";
import add from "@/components/qqmap/add";
export default {
  name: "store",
  components:{
    y_layout,
    add
  },
  data(){
    return{
      dialogTableVisible:false,
      form:{
        title:"",
        tel:"",
        address:"",
        lat:"",
        long:"",
      },
      page:1,
      total:0,
      list:[],
      loading:false,
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    closeForm(){
      this.form = {
        title:"",
        tel:"",
        address:"",
        lat:"",
        long:"",
      }
    },
    pageChange(e){
      this.page = e;
      this.load();
    },
    del(id){
      this.$api.store.del({id}).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    load(){
      this.loading = true;
      this.$api.store.page({
        page:this.page,
        merchant_id:parseInt(this.$route.query.merchant_id)
      }).then(res=>{
        this.list = res.list;
        this.total = res.total;
        this.loading = false;
      })
    },
    resetSubmit(){
      this.form = {
        title:"",
        tel:"",
        address:"",
        lat:"",
        long:"",
      }
    },
    submit(){
      if(!this.form.id){
        this.form.merchant_id = parseInt(this.$route.query.merchant_id)
        this.$api.store.add(this.form).then(()=>{
          this.$message.success("操作成功");
          this.dialogTableVisible = false;
          this.resetSubmit();
          this.load();
        })
        return;
      }
      this.$api.store.update(this.form).then(()=>{
        this.$message.success("操作成功");
        this.dialogTableVisible = false;
        this.resetSubmit();
      })
    },
    edit(e){
      this.form = e;
      this.dialogTableVisible = true;
    },
    point(lat,lng){
      this.form.lat = lat + "";
      this.form.long = lng + "";
    }
  }
}
</script>

<style scoped>

</style>